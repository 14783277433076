/*=============== GOOGLE FONTS ===============*/
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&family=Raleway:wght@500;600;700&display=swap");

/*=============== VARIABLES CSS ===============*/
:root {
  --header-height: 3rem;

  /*========== Colors ==========*/
  /* Change favorite color to match images */
  /*Green dark 190 - Green 171 - Grren Blue 200*/
  --hue-color: 190;

  /* HSL color mode */
  --first-color: hsl(var(--hue-color), 64%, 22%);
  --first-color-second: hsl(var(--hue-color), 64%, 22%);
  --first-color-alt: hsl(var(--hue-color), 64%, 15%);
  --title-color: hsl(var(--hue-color), 64%, 18%);
  --text-color: hsl(var(--hue-color), 24%, 35%);
  --text-color-light: hsl(var(--hue-color), 8%, 60%);
  --input-color: hsl(var(--hue-color), 24%, 97%);
  --body-color: hsl(var(--hue-color), 100%, 99%);
  --white-color: #FFF;
  --scroll-bar-color: hsl(var(--hue-color), 12%, 90%);
  --scroll-thumb-color: hsl(var(--hue-color), 12%, 75%);

  /*========== Font and typography ==========*/
  --body-font: 'Open Sans', sans-serif;
  --title-font: 'Raleway', sans-serif;
  --biggest-font-size: 2.5rem;
  --h1-font-size: 1.5rem;
  --h2-font-size: 1.25rem;
  --h3-font-size: 1rem;
  --normal-font-size: .938rem;
  --small-font-size: .813rem;
  --smaller-font-size: .75rem;

  /*========== Font weight ==========*/
  --font-medium: 500;
  --font-semi-bold: 600;

  /*========== Margenes Bottom ==========*/
  --mb-0-25: .25rem;
  --mb-0-5: .5rem;
  --mb-0-75: .75rem;
  --mb-1: 1rem;
  --mb-1-25: 1.25rem;
  --mb-1-5: 1.5rem;
  --mb-2: 2rem;
  --mb-2-5: 2.5rem;

  /*========== z index ==========*/
  --z-tooltip: 10;
  --z-fixed: 100;

  /*========== Hover overlay ==========*/
  --img-transition: .3s;
  --img-hidden: hidden;
  --img-scale: scale(1.1);
}

@media screen and (min-width: 968px) {
  :root {
    --biggest-font-size: 4rem;
    --h1-font-size: 2.25rem;
    --h2-font-size: 1.75rem;
    --h3-font-size: 1.25rem;
    --normal-font-size: 1rem;
    --small-font-size: .875rem;
    --smaller-font-size: .813rem;
  }
}

p{
  font-family: 'roboto',sans-serif;
}

.change-theme, .change-theme-name {
  color: var(--text-color);
}

.change-theme {
  cursor: pointer;
  font-size: 1rem;
}

.change-theme-name {
  font-size: var(--small-font-size);
}

/* 3 boxes */
.three-column-images
{
  text-align: center;
  background: white;
  display: grid;
  grid-gap: 89px;
  grid-template-columns: 200px 200px 200px;
}

.three-column-images .row
{
  text-align:center;
  width: 50%;

}
.three-column-images img
{
  margin-bottom: 0px;
  margin-top: 6px;
    border: unset;
}

.three-column-images a
{
  font-weight:bold;
}
.choc-one{
  color: #EFC650;
  font-size: 18px;
  font-weight: 400;
  margin: 0;
  font-family: "Iskry",serif;
  text-transform: uppercase;
}
.choc-two{
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
  font-family: 'Poppins';
}
.box{
  grid-column-gap: 0px;
  display: flex;
  width: 100%;
  grid-row-gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(198px, 1fr));
  justify-content: center;
}
.box-1{
   /* border: 1px solid rgb(139, 136, 136); */
   padding: 13px 13px 13px;
   position: relative;
}

.about{
  padding: 21px 17px 72px!important;
}

.container1 {
  display: grid;
  grid-template-columns: 713px auto;
  grid-gap: 28px;
  /* display: flex; */
  align-items: center;
  justify-content: right;
  padding: 63px 0px;
}

img {
  max-width: 100%;
  max-height:100%;
}

.text {
  font-size: 20px;
  padding-left: 20px;
  margin-top: -91px;
}
.choco1{
  color: rgba(0, 0, 0, 1);
  font-weight: 600;
  font-size: 25px;
  font-family: "Roboto", sans-serif;
}
.choco2{
  color: rgba(239, 198, 80, 1);
    font-weight: 400;
    font-size: 52px;
    font-family: "Iskry",serif;
}
.choco3{
  font-weight: 400;
  line-height: 22px;
  font-size: 14px;
  color: rgba(0, 0, 0, 1);
  font-family: 'roboto',sans-serif;

}
/*=============== BASE ===============*/
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: var(--header-height) 0 0 0;
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
  background-color: var(--body-color);
  color: var(--text-color);
}

h1, h2, h3 {
  color: var(--title-color);
  font-weight: var(--font-semi-bold);
  font-family: var(--title-font);
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

button,
input {
  border: none;
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
}

button {
  cursor: pointer;
}

input {
  outline: none;
}

.main {
  overflow-x: hidden;
}

/*=============== REUSABLE CSS CLASSES ===============*/
.section {
  padding: 4.5rem 0 2.5rem;
}

.section__title {
  font-size: var(--h2-font-size);
  color: var(--title-color);
  text-align: center;
  text-transform: capitalize;
  margin-bottom: var(--mb-2);
}

.container {
  max-width: 968px;
  margin-left: var(--mb-1);
  margin-right: var(--mb-1);
}

.grid {
  display: grid;
  gap: 1.5rem;
}

/*=============== HEADER ===============*/
.header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--z-fixed);
  background-color: #fff;
}

/*=============== NAV ===============*/
.nav {
  height: var(--header-height);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav__logo, .nav__toggle {
  color: #000000;
}
.nav_link{
  color: #000000;
    font-size: 16px;
    font-weight: 400;
}

.nav__logo {
  font-weight: var(--font-semi-bold);
}

.nav__toggle {
  font-size: 1.2rem;
  cursor: pointer;
}

.nav__menu {
  position: relative;
}
.img-log{
  width: 72px;
  height: auto;
}
.client-p{
  color: #000000;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    font-family: 'Poppins';
}
@media screen and (max-width: 767px) {
  .nav__menu {
    position: fixed;
    background-color: var(--body-color);
    top: 0;
    right: -100%;
    width: 70%;
    height: 100%;
    box-shadow: -1px 0 4px rgba(14, 55, 63, 0.15);
    padding: 3rem;
    transition: .4s;
  }
}

.nav__list {
  display: flex;
  flex-direction: column;
  row-gap: 2.5rem;
}

/* .nav__link {
  color: var(--text-color-light);
  font-weight: var(--font-semi-bold);
  text-transform: uppercase;
} */
.nav_link{
  color: #000000!important;
    font-size: 16px!important;
    font-weight: 400!important;
}
.nav_link .active-link{
  color: #000000!important;
    font-size: 16px!important;
    font-weight: 600!important;
}

.nav__link:hover {
  color: var(--text-color);
}

.nav__close {
  position: absolute;
  top: .75rem;
  right: 1rem;
  font-size: 1.5rem;
  color: var(--title-color);
  cursor: pointer;
}


/*test*/



/*test*/

.testimonial-one{
  background: rgba(255, 251, 240, 1);
  padding: 37px 100px 122px;
}
/* slider */
.rectangle-1{
  width: 193px;
  height: auto;

}
.frame-4{
  background: #fff;
  border-radius: 14px;
  display: grid;
  grid-template-columns: 221px 434px;
  text-align: center;
  box-shadow: 0px 0px 11.41891860961914px 0px rgba(0, 0, 0, 0.1);
  width: 43rem;

}
.group-15{
  padding: 20px 0px;
  font-family: 'roboto',sans-serif;
}
.frame-11{
  width: 296px;
  line-height: 55px;
  padding-bottom: 15px;
  color: rgba(239, 198, 80, 1);
  font-weight: 400;
  font-size: 52px;
  font-family: "Iskry",serif;
}
.main-frame{
  display: flex;
}
.about-sec2{
  text-decoration: underline;
    text-align: center;
    margin-top: 85px;
    color: rgba(0, 0, 0, 1);
    font-weight: 600;
    font-size: 25px;
    vertical-align: baseline;
   font-family: "Roboto", sans-serif;
}

/* -----*/





/* .row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap; 
  flex-wrap: wrap;
  padding: 0 4px;
  margin: 40px;
  margin: 0px 59px 40px;

} */
.mg-2{
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0 4px;
  margin: 40px;
  margin: 0px 59px 40px;
}

/* Create two equal columns that sits next to each other */
.column {
  -ms-flex: 30%; /* IE 10 */
  /* flex: 30%; */
  padding: 0 4px;
}
.gal-one{
  justify-content: center;
}
.column img {
  margin-top: 8px;
  vertical-align: middle;
}

/* Style the buttons */
.btn {
  border: none;
  outline: none;
  padding: 10px 16px;
  background-color: #f1f1f1;
  cursor: pointer;
  font-size: 18px;
}

.btn:hover {
  background-color: #ddd;
}

.btn.active {
  background-color: #666;
  color: white;
}


/*------------*/

.box{
  width: calc(100% - 0px);
  padding: 6px;
  border-radius: 20px;
  margin: 11px 47px 18px;
  line-height: 22.5px;
}

.box i.quote{
  font-size: 20px;
  color: #17a2b8;
}
.box .content{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: right;
  padding-top: 10px;
}
.box .info .name{
    font-size: 25px;
    font-weight: 500;
    color: #000000;
}
.box .info .job{
  font-size: 16px;
  font-weight: 500;
  color: #17a2b8;
}
.box .info .stars{
  margin-top: 2px;
}
.box .info .stars i{
  color: #17a2b8;
}
.box .content .image{
  height: 75px;
  width: 75px;
  padding: 3px;
  background: #17a2b8;
  border-radius: 50%;
}
.content .image img{
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 50%;
  border: 2px solid #fff;
}
.box:hover .content .image img{
  border-color: #fff;
}
.content{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: right;
  padding-top: 10px;
}
.box .info .name{
    font-size: 25px;
    font-weight: 500;
    color: #000000;
}
.image {
  --b: 22px;
  --s: 210px;
  --c: #EDC951;
  background-image: conic-gradient(from 90deg at top var(--b) left var(--b),#0000 25%,var(--c) 0), conic-gradient(from -90deg at bottom var(--b) right var(--b),#0000 100%,var(--c) 0);
  background-position: var(--_p,0%) var(--_p,0%), calc(100% - var(--_p,0%)) calc(100% - var(--_p,0%));
  background-size: var(--s) var(--s);
  background-repeat: no-repeat;
  cursor: pointer;
  transition: background-position .3s var(--_i,.3s), background-size .3s calc(.3s - var(--_i,.3s));
  padding: 34px 0px 18px 40px;
}
.about-sec{
  text-decoration: underline;
  text-align: center;
  margin-top: -42px;
  color: rgba(0, 0, 0, 1);
  font-weight: 600;
  font-size: 25px;

}
.gallery-one{
  text-align: center;
  display: flex;
  justify-content: space-around;
  /* grid-gap: 482px; */
  margin-top: 50px;

}



/* .wrapper {
  border: 2px solid #f76707;
  border-radius: 5px;
  background-color: #fff4e6;
} */

.w-one > div {

      margin: 0px 109px -16px;

}
.w-one{
  margin-top: 33px;
}
.wrapper {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  gap: 10px;
  /* grid-auto-rows: 100px; */
  grid-template-areas:
    "a a a a b b b b"
    "a a a a b b b b"
    "c c c c d d d d"
    "c c c c d d d d";
  align-items: start;
}
.item1 {
  grid-area: a;
  
}
.item2 {
  grid-area: b;
  text-align: right;
  bottom: 8px;
    position: relative;
}

.item3 {
  grid-area: c;
}
.item4 {
  grid-area: d;
}


@media (max-width: 1045px) {
  .wrapper .box{
    width: calc(50% - 10px);
    margin: 10px 0;
  }
}
@media (max-width: 702px) {
  .wrapper .box{
    width: 100%;
  }
}


/* show menu */
.show-menu {
  right: 0;
}

/* Change background header */
.scroll-header {
  background-color: var(--body-color);
  box-shadow: 0 0 4px rgba(14, 55, 63, 0.15);
}

.scroll-header .nav__logo,
.scroll-header .nav__toggle {
  color: var(--title-color);
}

/* Active link */
.active-link {
  position: relative;
  color: var(--title-color);
}

.active-link::before {
  content: '';
  position: absolute;
  background-color: var(--title-color);
  width: 100%;
  height: 2px;
  bottom: -.75rem;
  left: 0;
}

/*=============== HOME ===============*/
.home__img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  object-fit: cover;
  object-position: 83%;
}


.home__container {
  position: relative;
  height: calc(100vh - var(--header-height));
  align-content: center;
  row-gap: 3rem;
}

.home__data-subtitle, 
.home__data-title, 
.home__social-link, 
.home__info {
  color: var(--white-color);
}

.home__data-subtitle {
  display: block;
  font-weight: var(--font-semi-bold);
  margin-bottom: var(--mb-0-75);
}

.home__data-title {
  font-size: var(--biggest-font-size);
  font-weight: var(--font-medium);
  margin-bottom: var(--mb-2-5);
}

.home__social {
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
}

.home__social-link {
  font-size: 1.2rem;
  width: max-content;
}

.home__info {
  background-color: var(--first-color);
  /* display: flex; */
  padding: 1.5rem 1rem;
  align-items: center;
  column-gap: 2rem;
  position: absolute;
  right: 0;
  bottom: 1rem;
  width: 228px;
  display: table-column-group;
  text-align: center;
  grid-template-columns: 1fr 2fr;
  height: 536px;
  background: #FFFFFF;
  border-radius: 22px;
  filter: drop-shadow(0px 0px 14px rgba(0, 0, 0, 0.13));
}
.text-block {
  border-radius: 10px;
  display: inline-grid;
  position: absolute;
  bottom: 43px;
    right: 34px;
  color: #000000;
  padding-left: 20px;
  padding-right: 20px;
  width: 134px;
  height: 32px;
  background: #FFFFFF;
  align-items: center;
  text-align: center;
  padding-top: 6px;
}
.text-block:hover{
  background: #361800;
  color: #fff;
}
/*testimonials */



article.row {
  width:100%;
  max-width:800px;
  margin:0 auto;
  padding:45px 0;
}
article.row div {
  width:49.4%;
  display:inline-block;
  position:relative;
  border:1px solid white;
}

/**** For your reference ****/

/* article.row div:hover {
  border:1px solid green;
} */

#idea-one img, #idea-two img, #idea-three img {
  position:absolute;
  /* bottom:-20px; */
  left:50px;
}

/*product*/
.single-service-area {
  position: relative;
  display: block;
  padding: 100px 0 92px;
}

.service-provide {
  position: relative;
  display: block;
  overflow: hidden;
  margin-top: -26px!important;
}
.sec-title-box.style2 {
  margin-top: -7px;
  padding-bottom: 42px;
}

.sec-title-box {
  position: relative;
  display: block;
  overflow: hidden;
  padding-bottom: 36px;
  margin-top: -8px;
}
.sec-title-box h3 {
  color: #222222;
  font-size: 24px;
  line-height: 37px;
  font-weight: 700;
  /* text-transform: uppercase; */
  margin: 0 0 16px;
}
.sec-title-box p {
  margin: 0;
}

    
.service-provide .single-provide:before {
     
  background: transparent !important; 
   /*content: "";
   z-index: 3;*/
}
.service-provide .single-provide:after {

   background: transparent!important; 
  
}

.single-service-sidebar .service-pages li a:before {
    
  color: #594ccb!important;
     /* margin-top: -27px!important;*/
  }
  .single-service-sidebar .service-pages li a {

  background: #eeedf5!important;
  border: 1px solid #f0eef9;
  padding: 14px 20px 13px;
  color: #5a5a5d!important;
  font-size: 16px;
  font-weight: 400!important;
  
}
.single-service-sidebar .single-sidebar {
 
  margin-top: 48px!important;
}
.service-pages{
  border-radius: 11px;
    padding: 9px;
    position: sticky;
    top: 0;
    background: white;
    z-index: 99;
}
.single-service-sidebar .service-pages li a {
  background: #fff!important;
  border: 1px solid #f0eef9;
  padding: 14px 20px 13px;
  color: #5a5a5d!important;
  font-size: 14px;
  font-weight: 400!important;
}
.single-service-sidebar .service-pages li a:hover {
text-decoration: underline!important;
}
.border-list{
  height: 43px;
  /* border: 1px solid #EAEAEC!important; */
  border: 1px solid #000;
  margin: 3px 11px 3px;
  border-radius: 12px;
  width: 173px;
  text-align: left;
  vertical-align: middle;
  align-items: center;
  display: inline-grid;
}
.img-sidebar{
  margin: 9px;

}
.side-name{
  font-size: 18px;
  font-weight: 400;
  color: #000;
  text-decoration: unset;

}
.side-name:hover{
  font-size: 18px;
  font-weight: 400;
  color: #000;
  text-decoration: unset;

}
.first-row{
  margin-bottom: 22px;
  z-index: 0;
}
 .active-bt{
  background: #EFC650;
  border: unset;
}
/* .first-row:hover{
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  border-radius: 27px;
  padding: 0;
  background: red;

} */
.btn-one {
  color: #cc1919 !important;
}
.btn-one:hover {
  background: #6a5cba !important;
  color: #fff!important;
}
.main-sec1{
   margin-top: 46px;
}

/*product detail */
.product-detail {
  padding: 8rem 5rem 5rem 10%;

}
.h1-b{
  color: rgba(0, 0, 0, 1);
    font-size: 32px;
    font-weight: 400;
    margin-top: 20px;

}
.about-h1{
  color: rgba(0, 0, 0, 1);
  font-weight: 500;
}
.about-p1{
  color: rgb(87 87 87);
    font-weight: 400;
    font-size: 14px;
}
.mob-img{
  border-radius: 8px 8px 0px 0px;
}
.detail-sub2 {
  color: rgba(0, 0, 0, 1);
  font-weight: 600;
  background: rgba(239, 198, 80, 1);
  margin-bottom: 0px;
  border: 2px solid rgba(239, 198, 80, 1);
  padding: 0px 17px 0px;
  font-size: 13px;
  border-radius: 7px;
  height: 33px;
  outline: unset;
}
.detail-sub2:hover {
  border: 2px solid rgba(239, 198, 80, 1);
  background: #fff;
}
.detail-sub2:active {
  outline: unset;
}
.sub-detail{
  display: flex;
    justify-content: space-between;
    padding: 10px;
    border-left: 1px solid rgba(0, 0, 0, 0.4);
    border-right: 1px solid rgba(0, 0, 0, 0.4);
    border-bottom: 1px solid rgba(0, 0, 0, 0.4);
    align-items: center;
}
.detail-sub1{
  margin-bottom: 0px;
  color: rgba(0, 0, 0, 1);
  font-weight: 400;
  font-size: 15px;

}


.contact-head{
  color: rgba(239, 198, 80, 1);
  font-size: 52px;
  font-weight: 400;
  font-family: "Iskry",serif;
  line-height: 46px;
  padding-bottom: 13px;
}
.main-contact{
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 672.69px;
  margin-top: 126px;
  margin-bottom: 87px;
}
.contact-in{
  margin-right: -11px;
}
.contact-input{
  border: 1px solid rgba(0, 0, 0, 1);
  border-radius: 10px;

}
.contact-sub{
  float: right;
    width: 94px;
    border-radius: 35px;
    background: transparent;
    border: 1px solid rgba(0, 0, 0, 1);
    margin: 14px 1px 14px;
    height: 43px;
    font-size: 15px;
    font-weight: 400;
    color: rgba(0, 0, 0, 1);
    outline: unset;
}
.contact-sub:hover{
  background: #361800;
  color: #fff;
}


.contact-one{
  height: 154px!important;
  padding: 9px;
}
.contact-input:focus{
   border-color: unset!important;
   box-shadow: unset;
}
.select-contact{
  background: #FFF;
    width: 100%;
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 1);
    height: 38px;
    color: #495057;
    outline: unset;
}
.select-contact:focus{
  border-color: unset!important;
}
.contact-paragraph{
  font-family: 'Poppins';
    color: rgb(94 90 90);
}
input[type="number"] {
  -moz-appearance: textfield;
}

@media (min-height: 300px){
nav ul {
    position: sticky;
    top: 0;
}

}


@media(max-width:750px){

 
  article.row div {
    min-width:300px;
    width:90%;
    margin:0 5%;
    text-align:center;
    display:block;
  }
  
  /* Center your text */
	#some-other-box h3 {
    text-align:center;
  }

  /* Center your image */
	#idea-one img, #idea-two img, #idea-three img {
    position:relative;
    margin:auto;
    left:inherit;bottom:inherit;
  }


  #idea-two {
    display:-webkit-box;
    display:-moz-box;
    display:box;-webkit-box-orient:vertical;
    -moz-box-orient:vertical;
    box-orient:vertical;
  }
 
	#idea-two div:first-of-type {
    -webkit-box-ordinal-group:2;
    -moz-box-ordinal-group:2;
    box-ordinal-group:2;
  }
}



.home__info-title {
  display: block;
  font-size: var(--small-font-size);
  font-weight: var(--font-semi-bold);
  margin-bottom: var(--mb-0-75);
}

.home__info-button {
  font-size: var(--smaller-font-size);
}

.home__info-overlay {
  overflow: var(--img-hidden);
}

.home__info-img {
  width: 145px;
  transition: var(--img-transition);
}

/* .home__info-img:hover {
  transform: var(--img-scale);
} */

/*=============== BUTTONS ===============*/
.button {
  display: inline-block;
  background-color: var(--first-color);
  color: var(--white-color);
  padding: 1rem 2rem;
  font-weight: var(--font-semi-bold);
  transition: .3s;
}

.button:hover {
  background-color: var(--first-color-alt);
}

.button--flex {
  display: flex;
  align-items: center;
  column-gap: .25rem;
}

.button--link {
  background: none;
  padding: 0;
}

.button--link:hover {
  background: none;
}

/*=============== ABOUT ===============*/
.about__data {
  text-align: center;
}

.about__container {
  row-gap: 2.5rem;
}

.about__description {
  margin-bottom: var(--mb-2);
}

.about__img {
  display: flex;
  column-gap: 1rem;
  align-items: center;
  justify-content: center;
}

.about__img-overlay {
  overflow: var(--img-hidden);
}

.about__img-one {
  width: 130px;
}

.about__img-two {
  width: 180px;
}

.about__img-one, 
.about__img-two {
  transition: var(--img-transition);
}

.about__img-one:hover, 
.about__img-two:hover {
  transform: var(--img-scale);
}



.container21 {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(261px, 1fr));
  gap: 20px;
  /* background: teal; */
  padding: 15px;
}
.container21 img {
  width: 100%;
  display: block;
  /* -webkit-filter: grayscale(1); */
  /* filter: grayscale(1); */
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  border-radius: 27px;
  
}
/* .container21 img:hover {
  transform: scale(1.04);
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
} */
.title-one{
  margin: 0px 12px 0px;

}
.img-p1{
  margin-top: 7px;
  margin-bottom: 7px;
  color: rgba(0, 0, 0, 1);
  font-weight: 400;
  font-size: 14px;
}
.sub-title{
  display: flex;
  justify-content: space-between;
  /* width: 263px; */
}
.title-sub1{
  color: rgba(0, 0, 0, 1);
font-weight: 400;
font-size: 15px;
}
.title-sub12{
color: rgba(0, 0, 0, 1);
font-weight: 500;
font-size: 18px;
}
.title-sub2{
  border: 1px solid rgba(239, 198, 80, 1);
  background: transparent;
  padding: 6px;
  font-size: 13px;
  border-radius: 7px;
  height: 33px;
  outline: unset;

}

.head-cookie{
  color: rgba(239, 198, 80, 1);
  font-size: 52px;
  font-weight: 400;
  font-family: "Iskry",serif;
  margin: 3px 24px -13px;
  z-index: -1;
  position: relative;

}
#sidebarWrap {
  height: 123px;
  width: 166px;
  float: right;
  position: relative;
  box-shadow: none;
  border: none;
  margin-top: 58px;
  padding: 0;
}

#main {
  width: 670px;
  height: 4000px;
 background: #333333;
}

#footer {
  clear: both;
  margin: 10px 0;
}

#sidebar {
  width: 200px;
  height: 300px;
  position: absolute;
/* background: #333333; */
}

#sidebar.fixed {
  position: fixed;
  top: 94px;

}

/*=============== DISCOVER ===============*/
.discover__card {
  position: relative;
  width: 200px;
  overflow: var(--img-hidden);
}

.discover__data {
  position: absolute;
  bottom: 1.5rem;
  left: 1rem;
}

.discover__title, 
.discover__description {
  color: var(--white-color);
}

.discover__title {
  font-size: var(--h3-font-size);
  margin-bottom: var(--mb-0-25);
}

.discover__description {
  display: block;
  font-size: var(--smaller-font-size);
}

.discover__img {
  transition: var(--img-transition);
}

.discover__img:hover {
  transform: var(--img-scale);
}

.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right {
  background-image: none;
}


/*=============== FOOTER ===============*/
.footer{
  background: #000000;
  padding: 3rem 37px 2rem!important;

}
.footer__container {
  row-gap: 5rem;
}

.footer__content {
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  row-gap: 2rem;
}

.footer__title, 
.footer__subtitle {
  font-size: var(--h3-font-size);
}

.footer__title {
  margin-bottom: var(--mb-0-5);
}

.footer__description {
  margin-bottom: var(--mb-2);
    font-weight: 400;
    line-height: 22px;
    color: rgba(255, 255, 255, 1);
    font-size: 14px;
}

.footer__social {
  font-size: 1.25rem;
  color: var(--title-color);
  margin-right: var(--mb-1-25);
}

.footer__subtitle {
  /* margin-bottom: var(--mb-1); */
  font-weight: 700;
    font-size: 35px;
    color: #FFFFFF;
    font-family: "Roboto", sans-serif;
}
.foot-para{
  
  margin-right: 12px;
}

.footer__item {
  margin-bottom: 0px;
  display: -webkit-box;
  font-family: 'roboto',sans-serif;
}

.footer__link {
  color: var(--text-color);
  font-weight: 400;
    line-height: 22px;
    color: rgba(255, 255, 255, 1);
    font-size: 14px;
}
.footer__link1 {
  color: var(--text-color);
  font-weight: 400;
    line-height: 22px;
    color: rgba(255, 255, 255, 1);
    font-size: 14px;
}


.footer__link:hover {
  color: hsl(0deg 0% 73.37%);
}

.footer__rights {
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
  text-align: center;
  border-top: 1px solid #FFFFFF;
  margin-top: 16px;
  margin-left: -37px;
    margin-right: -37px;
}
.foot-logo{
  width: 103px;
  height: auto;
  margin: 0px 0px 20px;
}
.footer__copy, .footer__terms-link {
  color: #FFFFFF;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 0px;
  margin-top: 13px;

}

.footer__terms {
  display: flex;
  column-gap: 1.5rem;
  justify-content: center;
}

.footer__terms-link:hover {
  color: var(--text-color);
}


/*========== SCROLL UP ==========*/
.scrollup {
  position: fixed;
  right: 1rem;
  bottom: -20%;
  background-color: var(--first-color);
  padding: .5rem;
  display: flex;
  opacity: .9;
  z-index: var(--z-tooltip);
  transition: .4s;
}

.scrollup:hover {
  background-color: var(--first-color-alt);
  opacity: 1;
}

.scrollup__icon {
  color: var(--white-color);
  font-size: 1.2rem;
}

/* Show scroll */
.show-scroll {
  bottom: 5rem;
}

/*=============== SCROLL BAR ===============*/
::-webkit-scrollbar {
  width: .60rem;
  background-color: var(--scroll-bar-color);
}

::-webkit-scrollbar-thumb {
  background-color: var(--scroll-thumb-color);
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(--text-color-light);
}

/*=============== MEDIA QUERIES ===============*/
/* For small devices */



@media screen and (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}
@media(max-width:1200px){
  .container1 {
    display: inline;
  }
}
/* For tall screens on mobiles y desktop*/
/* For large devices */
@media screen and (min-width: 1024px) {
  .container {
    margin-left: auto;
    margin-right: auto;
  }
  .home__container {
    grid-template-rows: 2fr .5fr;
  }
  /* .home__info {
    width: 242px;
    grid-template-columns: 1fr 2fr;
    column-gap: 2rem;
    height: 510px;
    background: #FFFFFF;
    border-radius: 22px;
    filter: drop-shadow(0px 0px 14px rgba(0, 0, 0, 0.13));
  } */
  .home__info-title {
    font-weight: 600;
    font-size: var(--normal-font-size);
    color: #000000;
    font-family: 'Poppins';
}
  .home__info-img {
    /* width: 240px; */
    border-radius: 50%;
    width: 151px;
    height: 151px;
  }
  .about__img-one {
    width: 230px;
  }
  .about__img-two {
    width: 290px;
  }
  .discover__card {
    width: 237px;
  }
  .discover__container {
    width: 700px;
  }
  .discover__data {
    left: 1.5rem;
    bottom: 2rem;
  }
  .discover__title {
    font-size: var(--h2-font-size);
  }
  .experience__content {
    margin: var(--mb-1) 0;
    column-gap: 3.5rem;
  }
  .experience__overlay:nth-child(1) {
    width: 463px;
    margin-right: 7rem;
  }
  .experience__overlay:nth-child(2) {
    width: 220px;
    top: 3rem;
  }
  .video__container {
    grid-template-columns: .7fr;
  }
  .video__description {
    padding: 0 8rem;
  }
  .place__container {
    gap: 3rem 2rem;
  }
  .place__card, .place__img {
    height: 263px;
  }
  .footer__content {
    justify-items: center;
  }
}


@media(max-width:900){
  .nav__menu {
    display: flex;
    column-gap: 24rem;
}
}
@media screen and (min-width: 820px) {
  .nav__menu {
    display: flex;
    column-gap: 42rem;
  }
}
/* @media(max-width: 800px) {
  .nav__menu {
    display: flex;
    column-gap: 22rem;
}
} */
@media screen and (max-width: 768px) {
.nav {
  height: calc(var(--header-height) + 1.5rem);
}
}
@media screen and (min-width: 768px) {
  body {
    margin: 0;
  }
  .nav {
    height: calc(var(--header-height) + 1.5rem);
    padding-right: 38px;
  }
  .nav__link {
    color: #000;
    /* color: var(--white-color); */
    text-transform: initial;
    font-size: 16px;
    font-weight: 400;
    text-decoration: none!important;
  }
  .nav__link .active-link {
    color: #000;
    /* color: var(--white-color); */
    text-transform: initial;
    font-size: 16px;
    font-weight: 600;
  }
  .nav__link:hover {
    color: #000000
  }
  .nav__dark {
    position: initial;
  }

  /* .nav__menu {
      display: flex;
      column-gap: 44rem;
  } */
  .nav__list {
    flex-direction: row;
    column-gap: 1rem;
  }
  .active-link::before {
    content: '';
    position: absolute;
    background-color: var(--title-color);
    width: 77%;
    height: 2px;
    bottom: -0.75rem;
    left: 0;
  }
  .nav__toggle, .nav__close {
    display: none;
  }
  .change-theme-name {
    display: none;
  }
  .change-theme {
    color: var(--white-color);
  }
  .active-link::before {
    background-color: #000000!important;
  }
  .scroll-header .nav__link {
    color: var(--text-color);
  }
  .scroll-header .active-link {
    color: var(--title-color);
  }
  .scroll-header .active-link::before {
    background-color: var(--title-color);
  }
  .scroll-header .change-theme {
    color: var(--text-color);
  }
  .section {
    padding: 7rem 0 2rem;
  }
  .home__container {
    height: 100vh;
    grid-template-rows: 1.8fr .5fr;
  }
  .home__data {
    align-self: flex-end;
  }
  .home__social {
    flex-direction: row;
    align-self: flex-end;
    margin-bottom: 3rem;
    column-gap: 2.5rem;
  }
  .home__info {
    bottom: -14rem;

  }
  .about__container {
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
  }
  .about__data, .about__title {
    text-align: initial;
  }
  .about__title {
    margin-bottom: var(--mb-1-5);
  }
  .about__description {
    margin-bottom: var(--mb-2);
  }
  .discover__container {
    width: 610px;
    margin-left: auto;
    margin-right: auto;
  }
  .discover__container,
  .place__container {
    padding-top: 2rem;
  }
  .experience__overlay:nth-child(1) {
    width: 363px;
    margin-right: 4rem;
  }
  .experience__overlay:nth-child(2) {
    width: 160px;
  }
  .subscribe__bg {
    background: none;
    padding: 0;
  }
  .subscribe__container {
    background-color: var(--first-color-second);
    padding: 3.5rem 0;
  }
  .subscribe__input {
    padding: 0 .5rem;
  }
  .footer__rights {
    flex-direction: row;
    justify-content: center;
  }
}

/* @media(max-width: 820px){
.nav__menu {
    display: flex;
    column-gap: 22rem;
}
} */
@media(max-width: 1199px){
  .nav__menu {
      display: flex;
      column-gap: 12rem;
  }
}
@media(max-width: 820px){
  .nav__menu {
      display: flex;
      column-gap: 12rem;
  }

.three-column-images {
  text-align: center;
  background: white;
  display: inline;
  }
  .nav__link{
    display: inline-grid;
    color: #000;
    width: 115px;
  }
}
@media screen and (min-height: 721px) {
  body {
    margin: 0;
  }
  .home__container, .home__img {
    height: 640px;
  }
}


/* For medium devices */
@media screen and (min-width: 568px) {
  .video__container {
    display: grid;
    grid-template-columns: .6fr;
    justify-content: center;
  }
  .place__container {
    grid-template-columns: repeat(3, max-content);
  }
  .subscribe__form {
    width: 470px;
    margin: 0 auto;
  }
}

@media(max-width:400px){
  .box{
  display: grid;
  }
}
@media screen and (max-width: 340px) {
  .place__container {
    grid-template-columns: max-content;
    justify-content: center;
  }
  .experience__content {
    padding: 0;
  }
  .experience__overlay:nth-child(1) {
    width: 190px;
  }
  .experience__overlay:nth-child(2) {
    width: 80px;
  }
  .home__info {
    width: 190px;
    padding: 1rem;
  }

}