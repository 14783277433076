.slide-one li {
  flex: 1 1 auto;
  position: relative;
  width:652px !important;
}




@media(max-width:767px) {

  .testimonial-one {
    background: rgba(255, 251, 240, 1);
    padding: unset;
  }
} 

  .react-multi-carousel-list li {
 width:738px !important;
}