.nav__links{
    color: black;

}
.nav__links.active::before {
    content: '';
    position: absolute;
    background-color: #000000eb;
    width: 46px;
    height: 4px;
    bottom: -0.75rem;
    top: 32px;
  }
  