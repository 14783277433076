/* .border-list{
    background: #EFC650;
} */
.border-list{
    height: 43px;
    /* border: 1px solid #EAEAEC!important; */
    border: 1px solid #000;
    margin: 3px 11px 3px;
    border-radius: 12px;
    width: 173px;
    text-align: left;
    vertical-align: middle;
    align-items: center;
    display: inline-grid
}
.side-names{
    font-size: 18px;
    font-weight: 400;
    color: #000;
    text-decoration: unset;
}
.side-names.active{
    background: #EFC650;
}

/* div.sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: yellow;
    padding: 50px;
    font-size: 20px;
  } */
  .is-sticky {
    position: fixed;
    top:  74px;
    z-index: 999;
    animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  }
  